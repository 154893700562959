import React from 'react';
import '../../App.css';
import OffersUserList from './OffersUserList';

const OffersUser = () => {
  return (
    <>
      <section className="cards container">
        <OffersUserList />
      </section>
    </>
  );
};

export default OffersUser;
